.founder{
  background-color: #ffffff;

}

.header{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 69px;
  text-align: center;
  text-transform: uppercase;
  color: #332A98;
  padding-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 44px;
  }
  @media (max-width: 850px) {
    font-size: 35px;
    line-height: 37px;
  }
  @media (max-width: 710px) {
    font-size: 30px;
    line-height: 33px;
  }
  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 25px;
    line-height: 27px;
  }
  @media (max-width: 360px) {
     font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }
}

.main{
display: flex;
  min-width: 100%;
  flex-direction: row;
  @media (max-width: 820px) {
    flex-direction: column;
  }

}

.portrait{
  width: 358px;
  @media (max-width: 1536px) {
    width: 300px;
  }

  @media (max-width: 1000px) {
    width: 250px;
  }
  @media (max-width: 850px) {
    width: 200px;
  }

}

.text{
padding-left: 20px;
  @media (max-width: 820px) {
    padding-left: 0px;
  }

}

.h3{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 28.28px;
  line-height: 34px;
  color: #332A98;
  text-align: left;
  margin-top: 0;
  @media (max-width: 820px) {
    margin-top: 20px;
    text-align: center;
  }
  @media (max-width: 710px) {
    font-size: 24px;
    line-height: 22px;
  }
  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 19px;
    line-height: 20px;
  }

}

.uList{
  text-align: left;
  margin-left: 20px;
  padding-left: 0;
  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.listItem{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 28.28px;
  line-height: 34px;
  color: #332A98;
  margin-bottom: 20px;
  @media (max-width: 1536px) {
    font-size: 23px;
    line-height: 26px;
  }

  @media (max-width: 1000px) {
    font-size: 17px;
    line-height: 20px;
  }
  @media (max-width: 850px) {
    margin-bottom: 14px;
  }
}