.wrapper{
  display: flex;
  flex: 1 1 auto;

  //margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  //border: 1px solid black;
  flex-direction: column;

overflow: hidden;
  @media (max-width: 850px) {
    width: 100% !important;
  }
 }

.cardBody{
  background: rgb(228,250,255);
  background: linear-gradient(0deg, rgba(228,250,255,1) 40%, rgba(210,211,255,1) 100%);
width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;


}
.bottomLine{
  display: flex;
  //background-image: url("./img/bottomLine.svg");
  //background-repeat: no-repeat;
  //background-size:cover;
  height: 20px;
}

.portraitHolder{
  width: 263px;
  height: 263px;
  min-width: 263px;
  min-height: 263px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
  border-radius: 50%;
  //border: solid white 4px;
  overflow: hidden;
  @media (max-width: 1280px) {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
  }
  @media (max-width: 1210px) {
    width: 170px;
    height: 170px;
    min-width: 170px;
    min-height: 170px;
  }
  @media (max-width: 1030px) {
    width: 140px;
    height: 140px;
    min-width: 140px;
    min-height: 140px;
  }
}

.portraitHolder > img{
width: 100%;
  height: auto;
}

.name{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  display: flex;
 text-align: left;
  color: #332A98;
  padding-left: 18px;
  white-space: pre-line;
  @media (max-width: 1536px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (max-width: 960px) {
    text-align: center;
    line-height: 23px;
    //background-color: #8f1f1f;
    margin-top:15px;
    padding: 0;
  }
}

.ul{
  padding-left: 20px;
  margin-left: 0;
}

.li{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #332A98;
  text-align: left;
  white-space: pre-line;
}

.cardTop{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 960px) {
   flex-direction: column;
  }
}