

.circle_holder {
    margin: 0 auto;
    width: 1920px;
    height: 960px;
    position: relative;
    /*overflow: hidden;*/
    transition: all .3s;
}

.circle {
    width: 1920px;
    height: 1920px;
    background: rgb(85, 58, 185);
    background: -moz-linear-gradient(90deg, rgba(85, 58, 185, 1) 36%, rgba(94, 46, 141, 1) 67%);
    background: -webkit-linear-gradient(90deg, rgba(85, 58, 185, 1) 36%, rgba(94, 46, 141, 1) 67%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553ab9", endColorstr="#5e2e8d", GradientType=1);
    border-radius: 50%;
    position: absolute;
    top: -960px;
    background-image: url("./img/bg_circle.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
    transition: all .3s;
    background-color: #02031a;
    -webkit-box-shadow: 0px -2px 10px 2px rgba(46, 46, 123, 0.7) inset;
    -moz-box-shadow: 0px -2px 10px 2px rgba(46, 46, 123, 0.7) inset;
    box-shadow: 0px -2px 10px 2px rgba(46, 46, 123, 0.7) inset;

}

.circle_bg{
    width: 1940px;
    height: 1940px;
    border-radius: 50%;
    background: rgb(85,58,176);
    background: -moz-linear-gradient(141deg, rgba(85,58,176,1) 32%, rgba(58,100,179,1) 79%);
    background: -webkit-linear-gradient(141deg, rgba(85,58,176,1) 32%, rgba(58,100,179,1) 79%);
    background: linear-gradient(141deg, rgba(85,58,176,1) 32%, rgba(58,100,179,1) 79%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553ab0",endColorstr="#3a64b3",GradientType=1);
    position: absolute;
    top: -960px;
    left:-40px;
    z-index: -3;
    transition: all .3s;

}

@media (max-width: 1920px) {
    .circle_holder {
        width: 1536px;
        height: 768px;
    }

    .circle {
        width: 1536px;
        height: 1536px;
        top: -768px;
    }

    .circle_bg{
        width: 1542px;
        height: 1542px;
        top: -762px;
        left:-20px;
    }
}


@media (max-width: 1536px) {
    .circle_holder {
        width: 1280px;
        height: 640px;
    }

    .circle {
        width: 1280px;
        height: 1280px;
        top: -640px;
    }

    .circle_bg{
        width: 1292px;
        height: 1292px;
        top: -640px;
        left:-20px;
    }
}


@media (max-width: 1280px) {
    .circle_holder {
        width: 1100px;
        height: 510px;
        /*background-color: darkred;*/
    }

    .circle {
        width: 1100px;
        height: 1100px;
        top: -510px;
    }

    .circle_bg{
        width: 1100px;
        height: 1100px;
        top: -510px;
        left:-20px;
    }
}

@media (max-width: 1200px) {
    .circle_holder {
        width: 1060px;
        height: 530px;
        /*background-color: darkred;*/
    }

    .circle {
        width: 1060px;
        height: 1060px;
        top: -530px;
    }

    .circle_bg{
        width: 1060px;
        height: 1060px;
        top: -530px;
        left:-20px;
    }
}

@media (max-width: 980px) {
    .circle_holder {
        width: 900px;
        height: 450px;
        /*background-color: darkred;*/
    }

    .circle {
        width: 900px;
        height: 900px;
        top: -450px;
        left: -40px;
    }

    .circle_bg{
        width: 900px;
        height: 900px;
        top: -450px;
        left:-60px;
    }
}


@media (max-width: 768px) {

    .circle_holder {
        width: 700px;
        height: 350px;

    }

    .circle {
        width: 700px;
        height: 700px;
        top: -350px;
        left: 0px;
    }

    .circle_bg{
        width: 700px;
        height: 700px;
        top: -355px;
        left:-20px;
    }
}


@media (max-width: 640px) {

    .circle_holder {
        width: 400px;
        height: 400px;
        /*background-color: darkred;*/
        margin-top: 30px;
        position: relative;
        /*display: none;*/
        /*background-color: chartreuse;*/
    }

    .circle {
        top:0px;
        width: 400px;
        height: 400px;
        position: relative !important;
        /*top: 60px;*/
        /*background-size: 100%;*/
        background-size: 400px;
        background-position: center;
        background-position-y: 60px;
        background-image: url("./img/bg_circle_2.jpg");
    }

    .circle_bg{
        width: 400px;
        height: 400px;
        top: 20px;
        /*left:-20px;*/
        left:-6px;
        opacity: 40%;
    }
}


@media (max-width: 440px) {

    .circle_holder {
        width: 90%;
        height: content-box;
        position: relative;
        /*background-color: chartreuse;*/
        height: auto;
    }

    .circle {
        width: 358px;
        height: 358px;
        position: relative;
        margin: 0 auto;
    }

    .circle_bg{
        width: 358px;
        height: 358px;
        top: 10px;
        left:0px;
    }
}


@media (max-width: 400px) {

    .circle_holder {
        width: 90%;
        height: content-box;
        position: relative;
    }

    .circle {
        width: 288px;
        height: 288px;
        position: relative;
        margin: 0 auto;
    }

    .circle_bg{
        width: 288px;
        height: 288px;
        top: 10px;
        left:11px;
        /*display: none;*/
    }
}


@media (max-width: 350px) {

    .circle_holder {
        width: 90%;
        height: 220px;
        /*background-color: darkred;*/
    }

    .circle {
        width: 210px;
        height: 210px;
        /*top: 20px;*/
        /*left: 7%;*/
        margin: 0 auto;
    }

    .circle_bg{
        width: 210px;
        height: 210px;
        top: 8px;
        left:20px;
    }
}