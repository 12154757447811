
.circleHolder {
    position: absolute;
    top: 0px;
    right: -200px;
    transition: all .3s;
    opacity: 0.90;
}


.circle {
    width: 1100px;
    height: 1100px;
    background-image: url("../God/img/god_bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    transition: all .3s;
    position: relative;
    /*opacity: 0.6;*/
}


@media (max-width: 1920px) {
    .circleHolder {
        right: -150px;
    }

    .circle {
        width: 900px;
        height: 900px;
    }
}

@media (max-width: 1536px) {

    .circleHolder {
        right: -130px;
    }

    .circle {
        width: 750px;
        height: 750px;
    }
}

@media (max-width: 1280px) {

    .circleHolder {
        right: -100px;
    }

    .circle {
        width: 450px;
        height: 450px;
    }
}

/*=======*/
@media (max-width: 1200px) {

    .circleHolder {
        right: -90px;
    }

    .circle {
        width: 450px;
        height: 450px;
    }
}

/*@media (max-width: 1000px) {*/

/*    .circleHolder {*/
/*        right: -10px;*/
/*    }*/

/*    .circle {*/
/*        width: 400px;*/
/*        height: 400px;*/
/*    }*/
/*}*/

@media (max-width: 1000px) {

    .circleHolder {
        right: -80px;
    }

    .circle {
        width: 390px;
        height: 390px;
    }
}

@media (max-width: 768px) {

    .circleHolder {
        right: -80px;
    }

    .circle {
        width: 340px;
        height: 340px;
    }
}

@media (max-width: 640px) {

    .circleHolder {
        left: 0px;
        right: 0px;
        /*background-color: chartreuse;*/
        width: 100%;
        /*opacity: 50%;*/
      }

    .circle {
        margin: 0 auto;
        width: 300px;
        height: 300px;
        background-image: none;
        /*background-color: blue;*/
        /*display: none;*/
        /*background-color: blue;*/

    }
}

@media (max-width: 440px) {

    .circleHolder {
        left: 0px;
        right: 0px;
        width: 100%;
        height: 410px;
        margin: 0px;

    }

    .circle {
        /*margin: 0 auto;*/
        width: 200px;
        height: 200px;
        background-image: none;
        /*background-color: blue;*/

    }
}



@media (max-width: 400px) {

    .circleHolder {
        right: -30px;
    }

    .circle {
        width: 170px;
        height: 170px;
    }
}

@media (max-width: 350px) {

    .circleHolder {
        right: 0px;
        /*background-color: darkred;*/
        height: 250px;
    }

    .circle {
        width: 200px !important;
        height: 200px!important;
        /*display: none;*/
    }
}