.lead{
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
}

/*@media (max-width: 1920px)*/
/*@media (max-width: 1536px)*/
/*@media (max-width: 1280px) */
/*@media (max-width: 768px) */
/*@media (max-width: 640px) */
/*@media (max-width: 400px) */

@media (max-width: 1920px){
    .lead{
        font-size: 29px;
    }
}

@media (max-width: 1536px) {
    .lead{
        font-size: 23px;
    }
}

@media (max-width: 1280px) {
    .lead{
        font-size: 20px;
        line-height: 26px;
    }
}

@media (max-width: 980px) {
    .lead{
        font-size: 18px;
        line-height: 25px;
    }
}

@media (max-width: 768px) {
    .lead{
        font-size: 16px;
        line-height: 20px;
    }
}


@media (max-width: 640px) {
    .lead{
        color: #ffffff;
    }
}

@media (max-width: 440px) {
    .lead{
        font-size: 18px;
        line-height: 26px;
    }
}

@media (max-width: 400px) {
    .lead{
padding-left: 20px;
padding-right: 20px;
    }
}

@media (max-width: 350px) {
    .lead{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 17px;
        line-height: 25px;
    }
}

@media (max-width: 300px) {
    .lead{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 17px;
        line-height: 23px;
    }
}

@media (max-width: 260px) {
    .lead{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        line-height: 20px;
    }
}