/*@media (max-width: 1920px)*/
/*@media (max-width: 1536px)*/
/*@media (max-width: 1280px) */
/*@media (max-width: 768px) */
/*@media (max-width: 640px) */
/*@media (max-width: 400px) */


.pain {
  width: 100%;
  background-color: #bde7ec;
  /*border: solid 1px red;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 40px;
  margin-top: 40px;

  @media (max-width: 590px) {
    display: block;
    margin-top: 40px;
  }

  @media (max-width: 473px) {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

}

.leftSideBlock {
  display: flex;

  min-height: 30px;

  flex: 1 1 auto;
  align-self: auto;
  @media (max-width: 590px) {
    display: none;
  }
}

.rightSideBlock {
  display: flex;
  background-color: #3A64B3FF;
  min-height: 30px;
  flex: 1 1 auto;
  align-self: auto;
  @media (max-width: 590px) {
    display: none;
  }
}

.numberContainer {
  position: relative;
  background: rgb(51, 42, 152);
  background: linear-gradient(90deg, rgba(51, 42, 152, 1) 0%, rgba(58, 100, 179, 1) 69%);
  flex: 1 1 auto;
  align-self: auto;
  color: #817BC0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.number {
  position: absolute;
  /*background: red;*/
  display: flex;
  width: 160px;
  text-align: right;
  //background: #3439A9;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Playfair Display', serif;
  font-size: 144px;
  margin-top: -30px;

  @media (max-width: 1920px) {
    font-size: 130px;
  }

  @media (max-width: 1690px) {
    font-size: 90px;
  }

  @media (max-width: 1600px) {
    font-size: 60px;
  }
  @media (max-width: 1536px) {
    font-size: 100px;
  }

  @media (max-width: 1430px) {
    font-size: 80px;
  }

  @media (max-width: 1365px) {
    font-size: 60px;
  }

  @media (max-width: 1340px) {
    font-size: 60px;
  }

  @media (max-width: 1280px) {
    font-size: 70px;
  }

  @media (max-width: 1200px) {
    font-size: 90px;
  }

  @media (max-width: 1160px) {
    font-size: 80px;
  }

  @media (max-width: 1140px) {
    font-size: 70px;
  }

  @media (max-width: 1110px) {
    font-size: 50px;
  }

  @media (max-width: 1070px) {
    font-size: 40px;
  }

  @media (max-width: 1050px) {
    font-size: 30px;
  }

  @media (max-width: 1030px) {
    font-size: 40px;
  }
}


.brushContainer {
  box-sizing: border-box;
  display: flex;
  background-color: #bde7ec;
  min-width: 150px;
  width: 150px;

  @media (max-width: 1920px) {
    min-width: 100px !important;
    width: 100px !important;
  }

  @media (max-width: 1536px) {
    min-width: 90px !important;
    width: 90px !important;
  }

  @media (max-width: 1340px) {
    min-width: 70px !important;
    width: 70px !important;
  }

  @media (max-width: 640px) {
    min-width: 50px !important;
    width: 50px !important;
  }

  @media (max-width: 610px) {
    min-width: 40px !important;
    width: 40px !important;
  }

  @media (max-width: 590px) {
    display: none;
  }
}

.brushContainerLeft {
  box-sizing: border-box;
  background-image: url("./img/brush_left.svg");
  background-position: top left;
  background-size: 70%;

  background-repeat: no-repeat;
}

.brushContainerRight {
  box-sizing: border-box;
  background-image: url("./img/brush_right.svg");
  background-repeat: no-repeat;
    background-position: top right !important;
    background-size: 70%;
font-size: 0px;
  border-right: 10px solid #3a64b3;
  //margin-right: -2px;

}

.centerBlock {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-right: 40px !important;
  flex-wrap: nowrap;
  box-sizing: border-box;
  @media (max-width: 1536px) {
    padding-right: 30px !important;
  }
  @media (max-width: 590px) {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  @media (max-width: 400px) {
    box-sizing: border-box;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.h1 {
  font-family: 'Playfair Display', serif;
  color: #3439A9;
  font-size: 81px;
  font-weight: 900;
  font-style: italic;
  line-height: 77px;
  white-space: pre-wrap;
  margin-top: -13px;
  display: flex;


  @media (max-width: 1920px) {
    font-size: 70px;
    line-height: 70px;
  }

  @media (max-width: 1536px) {
    font-size: 60px;
    line-height: 65px;
  }
  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 55px;
  }
  @media (max-width: 1200px) {
    font-size: 40px;
    line-height: 44px;
    margin-top: -6px;
  }
  @media (max-width: 980px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 904px) {
    font-size: 30px;
    line-height: 33px;
  }

  @media (max-width: 590px) {
    margin: 0 auto;
  }
  @media (max-width: 400px) {
    font-size: 22px;
    line-height: 22px;

  }

}

.h2 {
  font-family: 'Playfair Display', serif;
  font-size: 44px;
  color: #3439A9;
  font-weight: 900;
  font-style: italic;
  line-height: 44px;
  margin-top: 10px;
  @media (max-width: 1920px) {
    font-size: 35px;
    line-height: 39px;
  }
  @media (max-width: 1536px) {
    font-size: 30px;
    line-height: 33px;
  }
  @media (max-width: 1280px) {
    font-size: 25px;
    line-height: 27px;
  }
  @media (max-width: 980px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 590px) {
    margin-top: 5px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
    line-height: 18px;
  }
}

.mobileNumber {
  display: none;

  @media (max-width: 590px) {
    display: flex;
    background-color: #bde7ec;
    text-align: center;
    flex-direction: row;
    min-height: 60px;
    align-items: stretch;
    justify-content: center;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    color: #ffffff;
  }
}

.mobileNumberInnerPart {
  width: 33.33333%;
  min-height: 100%;
  padding-bottom: 10px;
}

.mobileNumberInnerPart__left {
  background-image: url("./img/brush_right.svg");
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: right;
  border-right: #3c66b1 10px solid;
}

.mobileNumberInnerPart__right {
  background-image: url("./img/brush_left.svg");
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: left;
  border-left: #3c66b1 10px solid;
}

.mobileNumberInnerPart__center {
  background-color: #3c66b1;
}