.logo {
    width: 60%;
    animation: App-logo-spin infinite 120s linear;
    opacity: .7;
}

@media (max-width: 640px) {
    .logo {
        width: 270px;
        animation: App-logo-spin infinite 120s linear;
        opacity: .7;
        left: 26px;
        top:40px;
        position: absolute;
    }
}


@media (max-width: 440px) {
    .logo {
        width: 250px;
        animation: App-logo-spin infinite 120s linear;
        opacity: .7;
        left: -22px;
        top:34px;
        position: absolute;
    }
}


@media (max-width: 400px) {
    .logo {
        width: 200px;
        animation: App-logo-spin infinite 120s linear;
        opacity: .7;
        left: -9px;
        top:44px;
        position: absolute;
    }
}

@media (max-width: 350px) {
    .logo {
        width: 150px;
        left: 30px;
        top:28px;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}