/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.logo_white {
    width: 250px;
    cursor: pointer;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

@media (max-width: 1536px) {
    .logo_white {
        width: 210px;
    }
}

@media (max-width: 1280px) {
    .logo_white {
        width: 180px;
        margin: 30px;
    }
}

@media (max-width: 1200px) {
    .logo_white {
        width: 160px;
        margin: 20px;
    }
}

@media (max-width: 980px) {
    .logo_white {
        width: 130px;
        margin: 14px;
    }
}

@media (max-width: 768px) {
    .logo_white {
        width: 90px;
    }
}

@media (max-width: 640px) {
    .logo_white {
        width: 250px;
        margin: 40px;
    }
}

@media (max-width: 440px) {
    .logo_white {
        width: 230px;
        margin: 30px;
    }
}

@media (max-width: 400px) {
    .logo_white {
        width: 220px;
        margin: 30px;
    }
}

@media (max-width: 350px) {
    .logo_white {
        width: 200px;
        margin: 20px;
    }
}

@media (max-width: 300px) {
    .logo_white {
        width: 160px;
        margin: 20px;
    }
}

@media (max-width: 260px) {
    .logo_white {
        width: 140px;
        margin: 20px;
    }
}

.logo_white:hover{
     -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

