.joinUsArea{
  display: flex;
  min-width: 100%;
  min-height: 300px;
  width: 100%;
 height: 300px;
  background-image:
  url("./img/hends.jpg")  ;
  background-repeat: no-repeat;
  background-position: bottom ;
  background-size: 30%;
  background-color: #353393;
  margin-top:-40px;
  align-items: center;
  justify-content: center;
  position: relative;


  @media (max-width: 1650px) {
    background-size: 50%;
    background-position: center ;
  }

  @media (max-width: 1320px) {
    background-size: 70%;
    background-position: center ;
  }

  @media (max-width: 1050px) {
    background-size: 70%;
    background-position: center ;
  }

  @media (max-width: 780px) {
    background-size: 100%;
    background-position: center ;
  }

  @media (max-width: 630px) {
    background-size: 120%;
    background-position: center ;
  }

  @media (max-width: 590px) {
    background-size: 170%;
    background-position: center ;
  }

  @media (max-width: 470px) {
    background-size: 100%;
    background-position: center ;
  }

  @media (max-width: 400px) {
    background-size: 170%;
    background-position: center ;
    margin-top:0px;
  }

}
.bottom{
  width: 100%;

}