.wrapper{
  display: flex;
  background-color: #1D1854;
  flex-direction: column;

  justify-content: center;
  width: 100%;
  height: 200px;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 850px) {
    height: fit-content;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.wrapper a{
  text-decoration: none !important;
}

.text{
  display: flex;
height: 60px;
  font-style: normal;
  line-height: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;

  @media (max-width: 1250px) {
    font-size: 18px;
    line-height: 29px;
  }

  @media (max-width: 1150px) {
    font-size: 17px;
    line-height: 28px;
  }

  @media (max-width: 1000px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media (max-width: 870px) {
    font-size: 13px;
    line-height: 25px;
  }
  @media (max-width: 850px) {
    display: flex;
    order:1;
    margin-bottom: 20px;
  }
  @media (max-width: 300px) {
    letter-spacing: .3px;
    font-size: 10px;
    line-height: 40px;
  }
}

.text > div:nth-child(2){
  @media (max-width: 850px) {
    letter-spacing: .3px;
  }
}



.text > div {
  width: fit-content;
  //background-color: #8f1f1f;
  @media (max-width: 850px) {
    margin: 0 auto;
  }
}
.content{
 display: flex;
  align-items: center !important;
  justify-content: space-between;
  @media (max-width: 850px) {
flex-direction: column;
  }

}

//.block01{
//  text-align: left;
//  display: block;
//}

.block02{
  text-align: right !important;
  display: block;
  //background-color: #8f1f1f;
  height: fit-content;
}

.phone{
font-size: 30px;
  font-weight: 600;
  background-color: #7de38b;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color:#1D1854;
  cursor:pointer;
  transition: all .5s;

  @media (max-width: 1250px) {
    font-size: 26px;
    height: 55px;
  }

  @media (max-width: 1150px) {
    font-size: 24px;
    height: 53px;
  }

  @media (max-width: 1000px) {
    font-size: 17px;
    height: 43px;
  }
  @media (max-width: 850px) {
    order:0;
    font-size: 23px;
    height: 43px;
    margin-bottom: 20px;
  }

  @media (max-width: 850px) {
    order:0;
    font-size: 17px;
    height: 43px;
    margin-bottom: 20px;
  }
}

.phone:hover{
  background-color: #63b271;
  -webkit-box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);
  -moz-box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);
  box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);
}


.logotypeHolder{
  transition: all .5s;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  width: 150px;
  @media (max-width: 1250px) {
    width: 120px;
  }
  @media (max-width: 1150px) {
    width: 100px;
  }
  @media (max-width: 1000px) {
    width: 70px;
  }
  @media (max-width: 850px) {
    order:2;
  }
}




.logotypeHolder:hover{
  -webkit-box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);
  -moz-box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);
  box-shadow: 4px 4px 22px 0px rgba(7, 122, 107, 1);

    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);

}







.logotypeHolder >img{
  width: 100%;
  height: auto;

}

.iconHolder{
  width: 20px;
  height: 20px;
  margin-right: 4px;
  display: flex;
  @media (max-width: 1000px) {
    width: 17px;
    height: 17px;
  }
}

.iconHolder >img{
  width: 100%;
  height: auto;
}

.phoneHolder{
  width: 35px;
  height: 35px;
  margin-right: 5px;
  @media (max-width: 1000px) {
    width: 26px;
    height: 26px;
  }

}
.phoneHolder >img{
  width: 100%;
  height: auto;
}

.contact{
  display: flex;
  align-items: center;
}

.footerLink{
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  color:#1D1854;
}

.regularLink{
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  cursor: pointer;
  transition: all .5s;
}

.regularLink:hover{
  color: #b0cbff !important;
  text-shadow: 1px 1px 2px rgb(16, 154, 134);
}

.topLine{
  display: flex;
  width: 100%;
  min-width: 100%;
}




