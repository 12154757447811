.hero_box {
    display: flex;
    /*background-color: darkred;*/
    height: 810px;
    width: 100%;
    top: 0px;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
}

/*@media (max-width: 1920px)*/
/*@media (max-width: 1536px)*/
/*@media (max-width: 1280px) */
/*@media (max-width: 768px) */
/*@media (max-width: 640px) */
/*@media (max-width: 400px) */

@media (max-width: 1920px) {
    .hero_box {
        height: 650px;
        padding-top: 50px;
    }
}

@media (max-width: 1536px) {
    .hero_box {
        height: 520px;
        padding-top: 45px;
    }
}

@media (max-width: 1280px) {
    .hero_box {
        height: 340px;
        padding-top: 20px;

    }
}

@media (max-width: 768px) {
    .hero_box {
        height: 277px;
        padding-top: 16px;
    }
}

@media (max-width: 640px) {
    .hero_box {
        height: auto;
        padding-top: 10px;
        margin-top: 0px;
        position: relative;
    }
}

@media (max-width: 440px) {
    .hero_box {

    }
}

@media (max-width: 350px) {
    .hero_box {
padding-top: 0px;
    }
}