.title_group_desktop{
    /*background-color: chartreuse;*/
    display: block;
}

.visible_w_640px{
    display:none;
}

.title_h1 {
    font-size: 120px;
    line-height: 150px;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    color: #ffffff;
}

.title_h2 {
    font-size: 45px;
    line-height: 45px;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    color: #ffffff;
}

/*@media (max-width: 1920px)*/
/*@media (max-width: 1536px)*/
/*@media (max-width: 1280px) */
/*@media (max-width: 768px) */
/*@media (max-width: 640px) */
/*@media (max-width: 400px) */

@media (max-width: 1920px) {
    .title_h1 {
        font-size: 85px;
        line-height: 110px;
    }

    .title_h2 {
        font-size: 32px;
        line-height: 36px;
    }
}

@media (max-width: 1536px) {

    .title_h1 {
        font-size: 80px;
        line-height: 95px;
    }

    .title_h2 {
        font-size: 31px;
        line-height: 36px;
    }
}

@media (max-width: 1280px) {
    .title_h1 {
        margin-top: 10px;
        font-size: 80px;
        line-height: 95px;
    }

    .title_h2 {
        font-size: 36px;
        line-height: 40px;
        margin-top: 3px;
    }
}



@media (max-width: 1200px) {
    .title_h1 {
        margin-top: 10px;
        font-size: 70px;
        line-height: 85px;
    }

    .title_h2 {
        font-size: 34px;
        line-height: 38px;
        margin-top: 3px;
    }
}



@media (max-width: 980px) {
    .title_h1 {
        margin-top: 10px;
        font-size: 55px;
        line-height: 55px;
    }

    .title_h2 {
        font-size: 26px;
        line-height: 30px;
        margin-top: 6px;
    }
}




@media (max-width: 768px) {

    .title_h1 {
        font-size: 50px;
        line-height: 52px;
    }

    .title_h2 {
        font-size: 20px;
        line-height: 24px;
    }


}



@media (max-width: 660px) {

    .title_h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .title_h2 {
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width: 640px) {

    .title_h1 {
        font-size: 86px;
        line-height: 79px;
        /*font-size: 52px;*/
        /*line-height: 42px;*/
    }

    .title_h2 {
        font-size: 38px;
        line-height: 42px;
        margin-top: 24px;
    }

    .visible_w_640px{
        display:inline;
    }
}

@media (max-width: 440px) {

    .title_h1 {
        font-size: 74px;
        line-height: 67px;
    }

    .title_h2 {
        font-size: 30px;
        line-height: 37px;
        margin-top: 20px;
    }

    .visible_w_640px{
        display:inline;
    }
}

@media (max-width: 400px) {

    .title_h1 {
        font-size: 66px;
        line-height: 60px;
    }

    .title_h2 {
        font-size: 28px;
        line-height: 32px;
        margin-top: 16px;
    }

    .visible_w_640px{
        display:inline;
    }
}

@media (max-width: 350px) {

    .title_h1 {
        font-size: 60px;
        line-height: 52px;
    }

    .title_h2 {
        font-size: 26px;
        line-height: 30px;
        margin-top: 16px;
    }

    .visible_w_640px{
        display:inline;
    }
}

@media (max-width: 300px) {

    .title_h1 {
        font-size: 50px;
        line-height: 45px;
    }

    .title_h2 {
        font-size: 22px;
        line-height: 27px;
        margin-top: 12px;
    }

    .visible_w_640px{
        display:inline;
    }
}

@media (max-width: 260px) {

    .title_h1 {
        font-size: 40px;
        line-height: 39px;
    }

    .title_h2 {
        font-size: 19px;
        line-height: 22px;
        margin-top: 9px;
    }

    .visible_w_640px{
        display:inline;
    }
}

