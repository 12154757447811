/*
* Prefixed by:
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100% !important;
  background: rgb(188,231,236);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(18%, rgba(188,231,236,1)), color-stop(80%, rgba(224,213,236,1)));
  background: -o-linear-gradient(top, rgba(188,231,236,1) 18%, rgba(224,213,236,1) 80%);
  background: linear-gradient(180deg, rgba(188,231,236,1) 18%, rgba(224,213,236,1) 80%);



}
.AnonsHeader{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 69px;
  text-align: center;
  text-transform: uppercase;
  color: #332A98;
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 44px;
  }
  @media (max-width: 850px) {
    font-size: 35px;
    line-height: 37px;
  }
  @media (max-width: 710px) {
    font-size: 30px;
    line-height: 33px;
  }
  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 25px;
    line-height: 27px;
  }
  @media (max-width: 360px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }
}


.patch{
  display:none;
  @media (max-width: 590px) {
    display: block;
    background-color: #353393;
    width: 100%;
    height: 100px;
  }
}

.box {
  -webkit-box-shadow: 0 0 10px #cecece;
  box-shadow: 0 0 10px #cecece;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}