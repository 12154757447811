.background_gradient {
    background: rgb(55, 86, 196);
    background: -moz-linear-gradient(176deg, rgba(55, 86, 196, 0.9710259103641457) 15%, rgba(94, 46, 141, 0.36878501400560226) 44%, rgba(189, 231, 236, 1) 69%);
    background: -webkit-linear-gradient(176deg, rgba(55, 86, 196, 0.9710259103641457) 15%, rgba(94, 46, 141, 0.36878501400560226) 44%, rgba(2189, 231, 236, 1) 69%);
    background: linear-gradient(176deg, rgba(55, 86, 196, 0.9710259103641457) 15%, rgba(94, 46, 141, 0.36878501400560226) 44%, rgba(189, 231, 236, 1) 69%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3756c4", endColorstr="#ffffff", GradientType=1);
    /*height: 1200px;*/
    color: aquamarine;
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;
    box-sizing: border-box;
}


@media (max-width: 640px){
    .background_gradient{
        background-color: #162063;
    }
}

@media (max-width: 1280px){
    .background_gradient{
        padding-bottom: 90px;
    }
}

@media (max-width: 1200px){
    .background_gradient{
        padding-bottom: 30px;
    }
}

@media (max-width: 640px){
    .background_gradient{
        background: rgb(51,42,152);
        background: linear-gradient(180deg, rgba(51,42,152,1) 0%, rgba(58,100,179,1) 93%, rgba(189,231,236,1) 97%);
        padding-bottom: 120px;
    }
}

