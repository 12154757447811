/*@media (max-width: 1920px)*/
/*@media (max-width: 1536px)*/
/*@media (max-width: 1280px) */
/*@media (max-width: 768px) */
/*@media (max-width: 640px) */
/*@media (max-width: 400px) */


.AnswerItem{
  display: flex;
  align-items: stretch ;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 20px;



  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 460px) {
    margin: 0;
    padding-top:0;
    padding-bottom: 20px;
    display: block;
  }
}

.leftSide{
  display: flex;
  width: 200px;
  height: auto;
  min-width: 200px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: 1536px) {
    width: 190px;
    min-width: 190px;
  }

  @media (max-width: 1280px) {
    width: 150px;
    min-width: 150px;
    padding: 18px;
  }

  @media (max-width: 1200px) {
    width: 160px;
    min-width: 160px;
    padding: 14px;
  }

  @media (max-width: 1000px) {
    width: 120px;
    min-width: 120px;
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 110px;
    min-width: 110px;
    padding: 15px;
  }

  @media (max-width: 460px) {
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    padding: 0px;
    margin: 0;
  }
 }

.imgHolder{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 460px) {
    max-width: 100px;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 0;
 }
}

.imgHolder > img{
  width: 100%;
  height: auto;
}



.center{
  display: flex;
  flex: 1 1 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  text-align: left;
  align-items: center;
  color: #332A98;
  font-weight: 600;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;


  @media (max-width: 1536px) {
    font-size: 26px;
    padding: 15px 15px 15px 0px;
  }

  @media (max-width: 1280px) {
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 19px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 460px) {
    display: block;
    width: 100% !important;
    margin: 0;
  text-align: center !important;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.rightSide{
  display: flex;
  width: 100px;
  background-image: url("./img/answer_brush_white.svg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: left;

  @media (max-width: 460px) {
  //display: none!important;
    background-color: #8f1f1f;
  }

}