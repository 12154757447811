.god{
position: absolute;
    width: 683px;
    top:15%;
    right:24%;
    transition: all .1s;
}

.god_500_px{
    position: absolute;
    transition: all .1s;
    display: none;
}

@media (max-width: 1920px) {
    .god{
        width: 559px;
        top:15%;
        right:24%
    }
}

@media (max-width: 1536px) {
    .god{
        width: 466px;
        top:15%;
        right:24%
    }
}

@media (max-width: 1280px) {
    .god{
        width: 280px;
        top:15%;
        right:24%
    }
}

@media (max-width: 1000px) {
    .god{
        width: 241px;
        top:15%;
        right:24%
    }
}

@media (max-width: 888px) {
    .god{
        width: 235px;
        top:15%;
        right:24%
    }
}

@media (max-width: 768px) {
    .god{
        width: 209px;
        top:15%;
        right:24%
    }
}

@media (max-width: 640px) {
    .god{
        width: 192px;
        top:16%;
        right:18%;
        display: none;
    }

    .god_500_px{
        width: 300px;
        top:110px;
        right:10px;
        display: block;
    }
}

/*@media (max-width: 500px) {*/
/*    .god{*/
/*        width: 210px;*/
/*        right:2%;*/
/*        top:33%;*/
/*    }*/

/*    .god_500_px{*/
/*        width: 210px;*/
/*        right:2%;*/
/*        top:33%;*/
/*    }*/
/*}*/

@media (max-width: 440px) {
    .god_500_px{
        width: 270px;
        right: -18px;
        top:99px;
        /*background-color: chartreuse;*/
    }
}

@media (max-width: 400px) {
    .god_500_px{
        width: 210px;
        right: -10px;
        top:97px;
        /*background-color: chartreuse;*/
    }
}


@media (max-width: 350px) {
    .god_500_px{
        width: 160px;
        right:28px;
        top:69px;
    }
}