// style={{maxWidth:'100%', minWidth:'100%', minHeight:'120px', maxHeight:'120px'}}


.TextArea {
    width: 100%;
    margin-bottom: 34px;
    ::placeholder {
        color: #9990b4;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }
   }
   
   
   .TextArea textarea {
       max-width:100%; 
       min-width:100%;
       min-height:200px; 
       max-height:200px;
       border-radius:3px; 
       border: 1px solid #ffffff;
       padding-left: 10px;
       box-sizing:border-box;
       outline: none;
    //    transition: all 300ms ease-in-out;
       background-color: #ffffff;
       color: #37414d;
       font-size: 16px;
       font-family: 'Montserrat', sans-serif;
       font-weight: 500;
   }
   
   
   .TextArea span {
       color: #cf087c;
       font-size: 12px;
       
   }
   
