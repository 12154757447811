.App {
  text-align: center;
  background-color: #BBE7EDFF;
}

.patchSpacerHolder{
height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  //background-color: #ffdd00;
}

.patchSpacer {
  width: 100%;
  height: auto;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  //background-color: #8f1f1f;
}

