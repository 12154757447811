.wrapper{
  width: 100%;
  background-color: #342f95;
  overflow: hidden;
  padding-bottom: 40px;
  border-top: dotted #5b4e96 7px;
}

.title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 69px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 44px;
  }
  @media (max-width: 850px) {
    font-size: 35px;
    line-height: 37px;
  }
  @media (max-width: 710px) {
    font-size: 30px;
    line-height: 33px;
  }
  @media (max-width: 590px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 25px;
    line-height: 27px;
  }
  @media (max-width: 360px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }
}