.button{
  text-decoration: none;

  height: 60px !important;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  background-color: #a6e37d;
  font-size: 25px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #353393FF;
  cursor: pointer;
  opacity: .8;
  margin-bottom: 40px;
  @media (max-width: 470px){
    margin-top:40px;
  }
  @media (max-width: 360px){
    font-size: 20px;
    height: 50px;
  }
  @media (max-width: 300px){
    font-size: 16px;
    height: 40px;
  }
}

button:hover{
  background-color: #7de38b !important;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  -moz-box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  opacity: 1;
}


.button a:hover{
  background-color: #7de38b !important;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  -moz-box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  box-shadow: 4px 4px 15px 0px rgba(40, 110, 255, 0.75)!important;
  opacity: 1;
}