.container{
    display: flex;
    flex-direction: column;
    width: 1200px;
    /*background-color: #ee00ff;*/
    margin: 0 auto;
    flex-wrap: nowrap;
    box-sizing: border-box;


@media (max-width: 1536px) {
    width: 1000px;
    max-width: 1000px;
    //background: red;
}

    @media (max-width: 1280px) {
        width: 900px;
        max-width: 900px;
        //background: red;
    }

    @media (max-width: 1200px) {
        width: 800px;
        max-width: 800px;
        //background: red;
    }

    @media (max-width: 1030px) {
        width: 750px;
        max-width: 750px;
        //background: red;
    }

    @media (max-width: 1000px) {
        width: 700px;
        max-width: 700px;
        //background: red;
    }

    @media (max-width: 950px) {
        width: 600px;
        max-width: 600px;
    }

    @media (max-width: 850px) {
        width: 500px;
        max-width: 500px;
    }

    @media (max-width: 750px) {
        width: 450px;
        max-width: 450px;
    }

    @media (max-width: 710px) {
        width: 400px;
        max-width: 400px;
    }

    @media (max-width: 590px) {
        width: 100%;
        min-width: 100%;


    }

    @media (max-width: 460px) {
        display: block;
        padding: 0;
        margin:0;

    }


}