.experts {
  width: 100%;
  background-color: #bee5eb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1px;
  padding-top: 40px;
}

.column {
  width: 33.33333%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  @media (max-width: 1300px) {
    width: 50%;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
}

.item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 380px;
  height: 380px;
  margin: 0 auto;
  border-radius: 50%;
  background: rgb(60, 102, 177);
  background: url("./img/circle.svg"), -o-linear-gradient(135deg, rgba(60, 102, 177, 1) 30%, rgba(84, 62, 182, 1) 69%);
  background: url("./img/circle.svg"), linear-gradient(315deg, rgba(60, 102, 177, 1) 30%, rgba(84, 62, 182, 1) 69%);
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  -webkit-transition: all .1s;
  -o-transition: all .1s;
  transition: all .1s;

  @media (max-width: 1536px) {
    width: 310px;
    height: 310px;
  }

  @media (max-width: 1300px) {
    width: 380px;
    height: 380px;
  }
  @media (max-width: 1050px) {
    width: 330px;
    height: 330px;
  }
  @media (max-width: 950px) {
    width: 280px;
    height: 280px;
  }
  @media (max-width: 850px) {
    width: 235px;
    height: 235px;
  }
  @media (max-width: 760px) {
    width: 380px;
    height: 380px;
  }
  @media (max-width: 430px) {
    width: 320px;
    height: 320px;
  }
  @media (max-width: 360px) {
    width: 280px;
    height: 280px;
  }
  @media (max-width: 320px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 284px) {
    width: 230px;
    height: 230px;
  }
  @media (max-width: 260px) {
    width: 200px;
    height: 200px;
  }

}

.item ul {
  padding: 0px !important;
  margin: 0px;
}

.item li {
  list-style-type: none !important;
}

.icon {
  width: 93px;
  height: 95px;
  border-radius: 50%;
  background: rgb(60, 102, 177);
  background: linear-gradient(127deg, rgba(60, 102, 177, 1) 37%, rgba(84, 62, 182, 1) 98%);
  margin: 0 auto;
  transition: all .1s;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 4px 4px 16px 2px rgba(37, 43, 90, 0.61);
  -moz-box-shadow: 4px 4px 16px 2px rgba(37, 43, 90, 0.61);
  box-shadow: 4px 4px 16px 2px rgba(37, 43, 90, 0.61);

  @media (max-width: 1536px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1300px) {
    width: 86px;
    height: 86px;
  }
  @media (max-width: 1050px) {
    width: 77px;
    height: 77px;
  }
  @media (max-width: 950px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 850px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 760px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 430px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 360px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 260px) {
    width: 50px;
    height: 50px;
  }
}

.icon > img {
  width: 60%;
}

.expertTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56%;
  @media (max-width: 283px) {
  }
}

.expertText {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 33px;
  line-height: 29px !important;
  font-weight: 400;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 2px #3a307f;
  @media (max-width: 1536px) {
    font-size: 28px !important;
    line-height: 25px !important;
    margin-top: 15px !important;
  }
  @media (max-width: 1300px) {
    margin-top: 5px;
    font-size: 31px !important;
    line-height: 28px !important;
    height: 200px;
  }
  @media (max-width: 1050px) {
    font-size: 25px !important;
    line-height: 26px !important;
    height: 180px;
  }
  @media (max-width: 950px) {
    font-size: 24px !important;
    line-height: 23px !important;
    height: 150px;
   }
  @media (max-width: 850px) {
    font-size: 19px !important;
    line-height: 19px !important;
    max-height: 90px !important;
    height: 90px !important;
  }
  @media (max-width: 760px) {
    font-size: 33px !important;
    line-height: 33px !important;
    height: 250px !important;
    min-height: 250px !important;
  }
  @media (max-width: 430px) {
    font-size: 28px !important;
    line-height: 26px !important;
    height: 250px !important;
    min-height: 250px !important;
  }
  @media (max-width: 360px) {
    font-size: 23px !important;
    line-height: 23px !important;
    height: 250px !important;
    min-height: 250px !important;
    margin-top: 8px;
  }
  @media (max-width: 320px) {
    font-size: 21px !important;
    line-height: 20px !important;
    height: 250px !important;
    min-height: 250px !important;
  }
  @media (max-width: 283px) {
    font-size: 19px !important;
    line-height: 19px !important;
  }
  @media (max-width: 260px) {
    font-size: 14px !important;
    line-height: 15px !important;
    height: 160px !important;
  }
}


.expertTextSmall {
  font-weight: 500;
  font-size: 21px;
  line-height: 23px;
  margin-bottom: 0;
  margin-top: 4px;
  padding: 0;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  @media (max-width: 1536px) {
    font-size: 15px;
    height: 160px;
    letter-spacing: -.4px;
  }
  @media (max-width: 1536px) {
    font-size: 17px;

    letter-spacing: -.4px;
    line-height: 16px;
    margin-top: 5px;

  }
  @media (max-width: 950px) {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 16px;
    margin-top: 3px;

  }
  @media (max-width: 850px) {
    font-size: 13px;
    letter-spacing: -.4px;
    line-height: 13px;
    margin-top: 3px;

  }

  @media (max-width: 760px) {
    font-size: 17px;
    letter-spacing: 0px;
    line-height: 17px;
    margin-top: 3px;
  }

  @media (max-width: 430px) {
    font-size: 17px;
    letter-spacing: 0px;
    line-height: 17px;
    margin-top: 3px;
  }

  @media (max-width: 283px) {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 14px;
    margin-top: 3px;
  }

  @media (max-width: 260px) {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 12px;
    margin-top: 3px;
  }
}


.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;


}

