.wrapper{
  background: rgb(53,51,147);
  background: linear-gradient(0deg, rgba(53,51,147,1) 10%, rgba(117,101,166,1) 43%, rgba(187,231,237,1) 98%);
  min-width: 100%;
  min-height: 100%;;
}

.roundScreen{
  width: 500px;
  height: 500px;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: 590px) {
    padding-top: 20px;
    width: 400px;
    height: 400px;
  }

  @media (max-width: 590px) {
    padding-top: 20px;
    width: 90%;
    height: auto;
  }

}