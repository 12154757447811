.description{
  display: flex;
  //background-color: #E3FAFF;
  //border: 1px solid black;
  color: #332A98;
  padding: 0px;
margin-bottom: 20px;
  text-align: left;

  @media (max-width: 590px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}


.left{
  width: 40px;
  min-width: 40px;
  //background-color: #8f1f1f;

  background-image: url("./img/brush_left_light.svg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: right;
}

.right{
  width: 40px;
  min-width: 40px;
  background-image: url("./img/brush_right_light.svg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: left;
}

.center{
  display: flex;
  background-color: #E3FAFF;
  position: relative;
  align-items: center;
  padding: 20px 20px 20px 0;

  @media (max-width: 760px) {
flex-direction: column;
    text-align: center;
  }
}

.triangleHolder{
  position: absolute;
  width: 100%;
  height: 30px;
  top: -30px;
  display: flex;
}

.triangleHolder > img {
  height: 100%;
  margin: 0 auto;
}

.construction{
order: 1;
  width: 100px;
  @media (max-width: 760px) {
    order: 0;
  }
}

.text{
  font-size: 15px;
  order:0;
  padding-right: 10px;
  @media (max-width: 760px) {
    order: 1;
    margin-top: 15px;
    font-size: 12px;

  }
}

